<!-- eslint-disable max-len -->
<template>
  <div id="help-us-page" class="flex justify-center mt-10 mb-10">
    <b-loading :is-full-page="true" :active.sync="pageIsLoading"></b-loading>
    <div class="w-10/12 sm:w-3/5">
      <h1 class="self-start mb-5 text-4xl font-bold text-dark_red font-montserrat sm:hidden">
        {{$t("helpUs.title")}}
      </h1>
      <div class="px-4 py-6 mb-6 rounded-lg sm:px-2 sm:block sm:mt-36 sm:items-center sm:rounded-md red-box font-montserrat">
        <div class="py-8 mx-3 text-6xl font-semibold sm:pr-10">
          {{$t("helpUs.percentage")}}
        </div>
        <div class="flex items-center mx-3 text-base font-semibold uppercase text-up ">
          <p>
            {{$t('helpUs.percentMessage')}}
          </p>
        </div>
      </div>
      <div class="sm:text-xl font-roboto">
        <div class="mb-3 font-bold" v-html="$t('helpUs.text1')"/>
        <div class="mb-3 font-bold" v-html="$t('helpUs.text2')"/>
        <div class="mb-3" v-html="$t('helpUs.text3')"/>
        <div class="mb-3" v-html="$t('helpUs.text4')"/>
        <div class="mb-3" v-html="$t('helpUs.text5')"/>
      </div>
      <!-- Descarca formularul -->
      <div class="relative flex w-10/12 h-12 mb-8 text-xl text-white bg-black sm:-ml-44 sm:w-2/5 step-title mt-14 font-montserrat">
        <div class="text-blacked center">
          {{$t("helpUs.requirements.first")}}
        </div>
        <div class="absolute top-0 right-0 w-12 h-12 text-white transform translate-x-6 rounded-full circle bg-dark_red">
          <div class="center">
            1
          </div>
        </div>
      </div>
      <!-- Modify to be download button -->
      <div class="py-1 mx-auto my-2 mb-4 text-white rounded-md button bg-dark_red text-xl" @click="downloadEmptyForm()">
          <div class="center">
            {{$t("helpUs.downloadFormButton")}}
          </div>
        </div>

      <!-- Bullets -->
      <ul class="mb-6 sm:mb-8 text-dark_red font-roboto sm:text-xl">
        <li v-html="$t('helpUs.bullets.first')"/>
        <li v-html="$t('helpUs.bullets.second')"/>
        <li v-html="$t('helpUs.bullets.third')"/>
      </ul>

      <!-- !TODO: CHANGE TEXT -->

      <!-- Descarca declaratia  -->
      <div class="relative flex w-10/12 h-12 mb-8 text-xl text-white bg-black sm:-ml-44 sm:w-2/5 step-title mt-14 font-montserrat">
        <div class="text-blacked center">
          {{$t("helpUs.requirements.second")}}
        </div>
        <div class="absolute top-0 right-0 w-12 h-12 text-white transform translate-x-6 rounded-full circle bg-dark_red">
          <div class="center">
            2
          </div>
        </div>
      </div>

      <!-- !TODO: CHANGE TEXT -->
      <div class="mb-3 font-roboto sm:text-xl">
        {{$t("helpUs.text6")}}
      </div>
      <!-- Completeaza  -->
      <div class="relative flex w-10/12 h-12 mb-8 text-xl text-white bg-black sm:-ml-44 sm:w-2/5 step-title mt-14 font-montserrat">
        <div class="text-blacked center">
          {{$t("helpUs.requirements.third")}}
        </div>
        <div class="absolute top-0 right-0 w-12 h-12 text-white transform translate-x-6 rounded-full circle bg-dark_red">
          <div class="center">
            3
          </div>
        </div>
      </div>
      <div class="font-roboto sm:text-xl">
        {{$t("helpUs.text7")}}
      </div>

      <!-- Cum completezi si trimiti? -->
      <div class="step bg-dark_red text-white text-xl mt-10 mb-8 py-1.5 flex w-screen
                  justify-center items-center font-montserrat sm:ml-72 sm:rounded-l-xl sm:rounded-r-4xl">
        <div class="pl-6 sm:pl-0 sm:text-left center">
          {{$t("helpUs.questions.first")}}
        </div>
      </div>

      <!-- STEPS -->
      <div class="flex gap-4 mb-5 font-roboto">
        <div class="outer-circle">
          <div class="inner-circle">
            <div class="center">
              1
            </div>
          </div>
        </div>
        <div class="w-10/12 center sm:text-xl">
          {{$t("helpUs.steps.filling.first")}}
        </div>
      </div>

      <div class="flex gap-4 mb-5 font-roboto">
        <div class="outer-circle">
          <div class="inner-circle">
            <div class="center">
              2
            </div>
          </div>
        </div>
        <div class="w-10/12 center sm:text-xl">
          {{$t("helpUs.steps.filling.second")}}
        </div>
      </div>

      <div class="flex gap-4 mb-5 font-roboto">
        <div class="outer-circle">
          <div class="inner-circle">
            <div class="center">
              3
            </div>
          </div>
        </div>
        <div class="w-10/12 center sm:text-xl">
          {{$t("helpUs.steps.filling.third")}}
        </div>
      </div>

      <div class="flex gap-4 mb-5 font-roboto">
        <div class="outer-circle">
          <div class="inner-circle">
            <div class="center">
              4
            </div>
          </div>
        </div>
        <div class="w-10/12 center sm:text-xl">
          {{$t("helpUs.steps.filling.fourth")}}
        </div>
      </div>

      <!-- CUM SEMNEZI? -->
      <div class="step bg-dark_red sm:ml-72 sm:rounded-l-xl sm:rounded-r-4xl text-white text-xl mt-14 mb-8 py-1.5 flex w-screen
                  justify-center items-center font-montserrat">
        <div class="pl-10 sm:pl-0 sm:text-left center">
          {{$t("helpUs.questions.second")}}
        </div>
      </div>

      <!-- STEPS -->
      <div class="flex gap-4 mb-5">
        <div class="inline-block outer-circle">
          <div class="inner-circle">
            <div class="center font-roboto">
              1
            </div>
          </div>
        </div>
        <div  class="w-10/12 center font-roboto sm:text-xl">
          {{$t("helpUs.steps.signature.first")}}
        </div>
      </div>

      <div class="flex gap-4 mb-5">
        <div class="outer-circle">
          <div class="inner-circle">
            <div class="center font-roboto">
              2
            </div>
          </div>
        </div>
        <div class="w-10/12 center font-roboto sm:text-xl">
          {{$t("helpUs.steps.signature.second")}}
        </div>
      </div>

      <div class="flex gap-4 mb-5">
        <div class="outer-circle">
          <div class="inner-circle">
            <div class="center font-roboto">
              3
            </div>
          </div>
        </div>
        <div class="w-10/12 center font-roboto sm:text-xl">
          {{$t("helpUs.steps.signature.third")}}
        </div>
      </div>

      <div class="flex gap-4 mb-5">
        <div class="outer-circle">
          <div class="inner-circle">
            <div class="center font-roboto">
              4
            </div>
          </div>
        </div>
        <div class="w-10/12 center font-roboto sm:text-xl">
          {{$t("helpUs.steps.signature.fourth")}}
        </div>
      </div>

      <div class="mb-2 font-roboto sm:text-xl">
        {{$t("helpUs.messageSignature.first")}}
      </div>

      <div class="font-roboto sm:text-xl">
        {{$t("helpUs.messageSignature.second")}}
      </div>

      <!-- Trimite -->
      <div class="relative flex w-10/12 h-12 mb-8 text-xl text-white bg-black sm:-ml-44 sm:w-2/5 step-title mt-14 font-montserrat">
        <div class="text-blacked center font-roboto">
          {{$t("helpUs.requirements.fourth")}}
        </div>
        <div class="absolute top-0 right-0 w-12 h-12 text-white transform translate-x-6 rounded-full circle bg-dark_red">
          <div class="center font-roboto">
            4
          </div>
        </div>
      </div>

      <div class="font-roboto sm:text-xl text-black mb-4" v-html="$t('helpUs.sendText')"/>

      <!-- Bullets -->
      <ul class="mb-6 sm:mb-8 text-black font-roboto sm:text-xl">
        <li v-html="$t('helpUs.howToSendOptions.first')"/>
        <li v-html="$t('helpUs.howToSendOptions.second')"/>
        <li v-html="$t('helpUs.howToSendOptions.third')"/>
        <li v-html="$t('helpUs.howToSendOptions.fourth')"/>
      </ul>

      <!-- Unde vor merge banii tai -->
      <div class="px-5 py-5 mt-10 mb-6 rounded-lg black-box font-montserrat sm:flex sm:flex-col text-white"
           :class="{'bg-black': !error, 'bg-dark_red': error}">
        <div v-if="!formWasUploaded">
          <div class="text-2xl mb-2 ">
            {{$t("helpUs.textFromBlackBox.title")}}
          </div>
          <div class="sm mb-2">
            <div class="flex sm:my-4">
              <input type="file" accept=".pdf"  id="filled-form-file"/>
            </div>
          </div>
          <div class="py-1 my-2 mb-8 text-white rounded-md button bg-dark_red sm:py-o" @click="uploadHelpUsDocs()">
            <div class="center">
              {{$t("helpUs.textFromBlackBox.uploadFileButton")}}
            </div>
          </div>
        </div>
        <div v-else-if="error">
          <p class="text-2xl font-montserrat text-white text-center"> {{$t("helpUs.uploadUnsuccessfull")}} </p>
        </div>
        <div v-else>
          <p class="py-4 text-2xl text-white font-montserrat text-center"> {{$t("helpUs.uploadSuccessfull")}} </p>
        </div>
      </div>
      <div class="step bg-dark_red sm:ml-72 sm:rounded-l-xl sm:rounded-r-4xl text-white text-xl mt-14 mb-8 py-1.5 flex w-screen
                  justify-center items-center font-montserrat sm:w-1/4">
        <div class="pl-10 sm:pl-0 sm:text-left center">
          {{$t("helpUs.questions.third")}}
        </div>
      </div>

      <div class="font-roboto sm:text-xl sm:mb-10">
        {{$t("helpUs.whereMoneyText")}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formWasUploaded: false,
      error: false,
      pageIsLoading: false,
    };
  },
  methods: {
    uploadHelpUsDocs() {
      this.pageIsLoading = true;
      const emptyFormFile = document.getElementById("filled-form-file");
      window.uploadForm(emptyFormFile.files[0]).then(() => {
        this.formWasUploaded = true;
      }).catch(() => {
        this.error = true;
      }).finally(() => {
        this.pageIsLoading = false;
      });
    },
    downloadEmptyForm() {
      window.downloadForm();
    },
  },
};
</script>

<style lang="scss" scoped>
.red-box {
  background-color: $dark_red;
  color: $white;
  display: flex;
  justify-content: space-between;
  @media screen and(min-width:480px) {
    justify-content: center;
    padding-left: 10px;
  }
}
.black-box {
  justify-content: space-evenly;
  box-sizing: border-box;
}

.step-title::before {
  content: '';
  background-color: $black;
  width: 200px;
  height: 100%;
  position: absolute;
  left: -200px;
  top: 0;
}

.step {
  width: 110vw;
  transform: translateX(-18.2vw);
  @media screen and(min-width: 480px){
    max-width: 400px;
  }
}

.center {
  margin-top: auto;
  margin-bottom: auto;
  align-content: center;
}
.text-blacked{
  @media screen and(min-width: 480px){
    align-content: none;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    display: block;
  }
}

.button{
  width: 43%;
  display: flex;
  text-align: center;
  justify-content: center;
  @media screen and(min-width: 480px){
    width:20%;
    border: 0;
  }
}

@media screen and (min-width: 480px) {
    .uppercase{
      text-transform: uppercase;
      font-size: 36px;
      text-align: center;
    }
}
.circle {
  display: flex;
  justify-content: center;
}

.outer-circle {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px $dark_red solid;
}

.inner-circle {
  position: absolute;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin: -17px 0 0 -17px;
  background-color: $black;
  display: flex;
  justify-content: center;
  color: $white;
  font-weight: 600;
}

a {
  color: #3366CC!important;
}
</style>
